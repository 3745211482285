const en = document.querySelector('.lang-en');
const es = document.querySelector('.lang-es');

// Set max width on elements to transition from
function initWidths() {
  const elements = document.querySelectorAll('.caption .switch');

  setTimeout(() => {
    elements.forEach(element => {
      let caption;

      if (document.body.classList.contains('is-spanish')) {
        caption = element.querySelector('.es');
      } else {
        caption = element.querySelector('.en');
      }

      element.style.maxWidth = `${caption.clientWidth}px`;
    });
  }, 1000);
}

document.addEventListener('DOMContentLoaded', () => {
  initWidths();
}, false);

export function setWidths() {
  const elements = document.querySelectorAll('.caption .switch');

  elements.forEach(element => {
    let caption;

    if (document.body.classList.contains('is-spanish')) {
      caption = element.querySelector('.es');
    } else {
      caption = element.querySelector('.en');
    }

    element.style.maxWidth = `${caption.clientWidth}px`;
  });
}

// Handle english elements to fix widths and heights
en.addEventListener('click', () => {
  document.body.classList.remove('is-spanish');
  let caption, paragraph;
  const elements = document.querySelectorAll('.caption .switch');
  const containers = document.querySelectorAll('.section__info-container');

  elements.forEach(element => {
    caption = element.querySelector('.en');

    element.style.maxWidth = `${caption.clientWidth}px`;
  });

  containers.forEach(container => {
    paragraph = container.querySelector('.en');

    container.style.maxHeight = `${paragraph.clientHeight}px`;
  });
});

// Same for spanish
es.addEventListener('click', () => {
  document.body.classList.add('is-spanish');
  let caption, paragraph;
  const elements = document.querySelectorAll('.caption .switch');
  const containers = document.querySelectorAll('.section__info-container');

  elements.forEach(element => {
    caption = element.querySelector('.es');

    element.style.maxWidth = `${caption.clientWidth}px`;
  });

  containers.forEach(container => {
    paragraph = container.querySelector('.es');

    container.style.maxHeight = `${paragraph.clientHeight}px`;
  });
});

// Handle paragraph's height for switch language
export function setParagraphs() {
  const containers = document.querySelectorAll('.section__info-container');
  let paragraph;

  containers.forEach(container => {
    if (document.body.classList.contains('is-spanish')) {
      paragraph = container.querySelector('.es');
    } else {
      paragraph = container.querySelector('.en');
    }
    container.style.maxHeight = `${paragraph.clientHeight}px`;
  });
}

document.addEventListener('DOMContentLoaded', () => {
  setParagraphs();
}, false);

window.addEventListener('resize', () => {
  initWidths();
  setParagraphs();
}, false);
