import { loading } from './components/progress';
import { animateLinks } from './components/links';
import { handleTextures } from './components/scene';
import { handleTransitions } from './components/transitions';
import * as lang from './components/language';

loading();
animateLinks();
handleTextures();
handleTransitions();
lang;
