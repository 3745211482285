import { TimelineMax, Circ, Expo } from 'gsap';
import { CustomEase } from '../lib/CustomEase';

const suave = CustomEase.create('suave', 'M0,0 C0.502,0.282 0,1 1,1');

function remove() {
  const el = document.querySelector('.load__content');
  const bars = document.querySelectorAll('.load__bar');
  el.parentNode.removeChild(el);

  bars.forEach(bar => {
    bar.style.right = 'unset';
    bar.style.left = 0;
    bar.style.width = 0;
  });
}

export function loading() {
  // DOM elements
  const content = document.querySelectorAll('.load__text .span-wrapper span, .load__progress .span-wrapper span');

  // Progress value
  let progress = {
    value: 0
  };

  // Timeline
  const tl = new TimelineMax();

  tl
    .staggerTo(content, 2,
      { y: 0, ease: suave, delay: 2 },
      0.04
    )
    .to(progress, 4, {
      value: 100,
      ease: Circ.easeInOut,
      onUpdate: function() {
        document.querySelector('.load__progress-value').textContent = Math.trunc(progress.value);
      }
    }, '-=1')
    .staggerTo(content, 2,
      { y: -100, ease: suave, delay: 2 },
      0.04,
      '-=1.5')
    .staggerTo('.load__bar', 1,
      { width: 0, ease: suave, delay: 1 },
      0.1,
      '-=2.25',
      remove);
}
