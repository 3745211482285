import barba from '@barba/core';
import { TweenMax } from 'gsap';
import { CustomEase } from '../lib/CustomEase';
import { cancelRenderer, handleTextures, textures } from '../components/scene';
import { setWidths, setParagraphs } from '../components/language';

const suave = CustomEase.create('suave', 'M0,0 C0.502,0.282 0,1 1,1');
const elements = [...document.querySelectorAll('.load__bar')];
let savedTextures;

// Import barba and TweenMax from gsap
const pageTransition = {
  // Apply only if clicked link contains class
  custom: ({ current, next, trigger }) => trigger.classList && trigger.classList.contains('js-cta'),

  // Transition when current page leaves
  leave() {
    const done = this.async();

    TweenMax.staggerTo(elements, 0.75,
      { width: '100%', ease: suave },
      0.075,
      function() {
        elements.forEach(element => {
          element.style.left = 'unset';
          element.style.right = 0;
        });

        done();
      }
    );
  },

  // Transition when next page enters
  enter() {
    const done = this.async();

    TweenMax.staggerTo(elements, 0.75,
      { width: 0, ease: suave },
      0.075,
      function() {
        elements.forEach(element => {
          element.style.left = 0;
          element.style.right = 'unset';
        });

        done();
      }
    );
  },
};

const homeView = {
  namespace: 'home',
  beforeEnter() {
    setWidths();
  },
  beforeLeave() {
    // do something before leave transition of home
    savedTextures = textures;
    cancelRenderer();
  },
  afterEnter() {
    // do something after enter transition to home
    handleTextures(savedTextures);
  },
};

const aboutView = {
  namespace: 'about',
  beforeEnter() {
    setParagraphs();
  }
};

export function handleTransitions() {
  // Init barba.js with your transition.
  barba.init({
    transitions: [ pageTransition ],
    views: [ homeView, aboutView ]
  });
}
