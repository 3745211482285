import * as THREE from 'three';
import vertex from '../glsl/vertex.glsl';
import fragmentDefault from '../glsl/fragment-default.glsl';
import fragment1 from '../glsl/fragment-01.glsl';
import fragment2 from '../glsl/fragment-02.glsl';
import fragment3 from '../glsl/fragment-03.glsl';
import fragment4 from '../glsl/fragment-04.glsl';
import fragment5 from '../glsl/fragment-05.glsl';
import fragment6 from '../glsl/fragment-06.glsl';
import fragment7 from '../glsl/fragment-07.glsl';
import fragment8 from '../glsl/fragment-08.glsl';
import fragment9 from '../glsl/fragment-09.glsl';
import { TweenMax, Power4 } from 'gsap';


// Declare variables
let scene, camera, renderer;
let geometry, material, mesh, texture;
let uniforms;
let id;

// Different fragment shaders for each texture
let shaders = [
  fragment1,
  fragment2,
  fragment3,
  fragment4,
  fragment5,
  fragment6,
  fragment7,
  fragment8,
  fragment9
];

// Load textures
let texturePaths = [
  '../../img/flower.jpg',
  '../../img/cereal.jpg',
  '../../img/guy.jpg',
  '../../img/leaves.jpg',
  '../../img/neon.jpg',
  '../../img/octopus.jpg',
  '../../img/led.jpg',
  '../../img/chinoise-fille.jpg',
  '../../img/urban.jpg'
];

let numberOfTextures = texturePaths.length;
let loadedCount;
export let textures;
let loader = new THREE.TextureLoader();
let placeholder = new THREE.TextureLoader().load('../../img/placeholder.jpg');

export function handleTextures(savedTextures) {
  if (savedTextures) {
    // We have already loaded textures!
    textures = savedTextures;
    loadedCount = savedTextures.length;
    init();
    animate();
    return;
  }

  loadedCount = 0;
  textures = [];

  // Start scene when textures are loaded
  for (var i = 0; i < numberOfTextures; i++) {
    textures.push(loader.load(texturePaths[i], () => {
      loadedCount++;

      if (loadedCount === numberOfTextures) {
        // Initializers
        init();
        animate();
      }
    }));
  }
}

export function cancelRenderer() {
  cancelAnimationFrame(id);
}

// Main function
function init() {
  // Preparing the scene
  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  camera.position.z = 10;

  // Renderer
  renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas: document.querySelector('#textures')
  });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0xffffff, 1);

  // Geometry
  geometry = new THREE.PlaneBufferGeometry(10, 10, 32, 32);

  // Shader uniforms
  uniforms = {
    u_time: { type: 'f', value: 1.0 },
    u_animation: { type: 'f', value: 1.0 },
    u_resolution: { type: 'v2', value: new THREE.Vector2() },
    u_mouse: { type: 'v2', value: new THREE.Vector2() },
    u_displacement: { type: 'v2', value: new THREE.TextureLoader().load('../../img/displacements/disp-01.png') },
    u_texture: { type: 'v2', value: texture }
  };

  // Material
  material = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: vertex,
    fragmentShader: fragmentDefault
  });

  // Mesh
  mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);

  // Functions
  setDefaultTexture();
  swapTextures();
  changeShader();
  animateShader();
  onWindowResize();
  window.addEventListener('resize', onWindowResize, false);
}

// Set default white texture
function setDefaultTexture() {
  // console.log('set default texture');

  // Assign new texture
  texture = placeholder;

  // Update texture to scene
  material.uniforms.u_texture.value = texture;
  material.uniforms.u_texture.needsUpdate = true;
}

// Change textures on hover
function swapTextures() {
  // console.log('swap textures');

  const elements = document.querySelectorAll('.caption');

  elements.forEach(element => {
    element.addEventListener('mouseover', function() {
      for (let i = 0; i < elements.length; i++) {
        if (this.classList.contains(`js-scene-${i + 1}`)) {
          // Assign new texture
          texture = textures[i];

          // Update texture to scene
          material.uniforms.u_texture.value = texture;

          if (i === 4) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }

          material.uniforms.u_texture.needsUpdate = true;

          // Texture dimensions
          let textureWidth = texture.image.width * 0.001;
          let textureHeight = texture.image.height * 0.001;

          // Scale mesh to dimensions
          mesh.scale.set(textureWidth, textureHeight, 1);
        }
      }
    });
  });
}

// Change shaders
function changeShader() {
  const elements = document.querySelectorAll('.caption');
  let lastHover;

  elements.forEach(element => {
    element.addEventListener('mouseover', function() {
      // Restart time only when another element is hovered
      if (!(lastHover === this)) {
        uniforms.u_time.value = 1;
      }

      // Replace last hovered element
      lastHover = this;

      for (let i = 0; i < elements.length; i++) {
        if(this.classList.contains(`js-scene-${i + 1}`)) {
          // Update to respective shader
          material.fragmentShader = shaders[i];
          material.needsUpdate = true;
        }
      }
    });
  });
}

// Turn shader on an off on mouse event
function animateShader() {
  const parent = document.querySelector('.captions__wrapper');
  const elements = document.querySelectorAll('.caption');

  elements.forEach(element => {
    element.addEventListener('mouseover', function() {
      parent.classList.add('is-active');
      element.classList.add('is-hovered');

      TweenMax.to(uniforms.u_animation, 1.5, {
        value: 1.0,
        ease: Power4.easeOut
      });
    });
  });

  elements.forEach(element => {
    element.addEventListener('mouseleave', function() {
      parent.classList.remove('is-active');
      element.classList.remove('is-hovered');

      TweenMax.to(uniforms.u_animation, 1.5, {
        value: 0.0,
        ease: Power4.easeOut
      });
    });
  });
}

// Resize renderer and change uniform values
function onWindowResize(e) {
  let w = window.innerWidth;
  let h = window.innerHeight;

  camera.aspect = w / h;
  camera.position.z = w < 1034 ? 14 : 10;
  camera.updateProjectionMatrix();

  renderer.setSize(w, h);

  uniforms.u_resolution.value.x = w;
  uniforms.u_resolution.value.y = h;
}

// Animate
function animate() {
  id = requestAnimationFrame(animate);
  render();
}

// Render and update uniform value
function render() {
  uniforms.u_time.value += 1/60;
  renderer.render(scene, camera);
}
