import { TweenMax, TimelineMax, Circ } from 'gsap';

const suave = CustomEase.create('suave', 'M0,0 C0.502,0.282 0,1 1,1');

function flick() {
  const link = this.querySelector('.span-wrapper span');

  // Animate
  const tl = new TimelineMax();

  tl
    .to(link, 0.25, {
      y: '100%',
      ease: Circ.easeIn
    })
    .set(link, { y: '-100%' })
    .to(link, 0.5, {
      y: '0%',
      ease: Circ.easeOut
    });
}

export function animateLinks() {
  // DOM elements
  const triggers = document.querySelectorAll('.header__link, .lang-en, .lang-es');

  // Flick only on desktop > resolutions
  if (window.innerWidth > 980) {
    // Animate elements on hover
    triggers.forEach(trigger => trigger.addEventListener('mouseenter', flick));
  }
}
